import type {
	IAggregratedBank,
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	ISettings,
	ISettingsObjKey,
} from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const siteMgtApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		updateSettings: builder.mutation<IApiResponse<ISettings>, { key: ISettingsObjKey; value: unknown }>({
			query: ({ key, value }) => ({ url: '/admin/settings/update', body: { [key]: value }, method: 'PUT' }),
			invalidatesTags: ['User'],
		}),
		getAggregatedBanks: builder.query<IApiResponsePaginated<IAggregratedBank[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/payments/banks/aggregate', params }),
			providesTags: ['GetAggregatedBanks'],
		}),
		updateAggregatedBank: builder.mutation<IApiResponse, { enable: boolean; bankId: string }>({
			query: ({ enable, bankId }) => ({ url: `/admin/payments/bank/${bankId}/update`, method: enable ? 'PATCH' : 'PUT' }),
			invalidatesTags: ['GetAggregatedBanks'],
		}),
	}),
});

export const { useUpdateSettingsMutation, useGetAggregatedBanksQuery, useUpdateAggregatedBankMutation } = siteMgtApiSlice;
