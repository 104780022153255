import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DataInsight, Input, Text } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { formHasChanges, type ISellAssetType, spawnAppToast } from 'BreetHelpers';
import { selectFxRates, selectModals, useAppSelector, useUpdateAssetsRateMutation } from 'BreetRedux';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
	CryptoAssetRateSchema,
	type CryptoAssetRateSchemaType,
} from '../../wallet-and-withdrawal/SiteMgtWalletAndWithdrawal.helpers';
import { generateRatePairs } from '../SiteMgtRate.helpers';

const ViewEditCryptoAssetRate = () => {
	const modals = useAppSelector(selectModals);
	const fxRates = useAppSelector(selectFxRates);

	const [updateAssetRate, { isLoading }] = useUpdateAssetsRateMutation();

	const currentModal = modals.find((modal) => modal.id === 'viewEditCryotoAsset');
	const modalMetadata = currentModal?.metadata as { isEditing: boolean; data: ISellAssetType };

	const pairedAssetRates = generateRatePairs(modalMetadata.data.rates);

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm({
		resolver: yupResolver<Record<number | string, number>>(CryptoAssetRateSchema),
	});

	const handleFormValueInitUpdate = useCallback(() => {
		const { data } = modalMetadata;

		setValue('minimum', data.minimum);
		setValue('confirmations', data.confirmations);
		Object.entries(data.rates).forEach(([dollarRange, ngnValue]) => setValue(dollarRange, Number(ngnValue)));
	}, [modalMetadata, setValue]);

	useEffect(() => {
		handleFormValueInitUpdate();
	}, [handleFormValueInitUpdate]);

	const handleModalClose = useCallback(() => {
		closeModalById('viewEditCryotoAsset');
	}, []);

	const onSubmit = useCallback(
		(payload: unknown) => {
			const { minimum, confirmations, ...rest } = payload as CryptoAssetRateSchemaType;
			const shapedPayload = {
				_id: modalMetadata.data._id,
				minimum,
				confirmations,
				rates: { ...rest },
			};

			if (
				formHasChanges({
					defaultValues: modalMetadata.data,
					currentValues: { ...modalMetadata.data, ...shapedPayload },
				})
			) {
				updateAssetRate({ rates: [shapedPayload] })
					.unwrap()
					.then((resp) => {
						spawnAppToast({ dataMsg: resp, type: 'success' });
						handleModalClose();
					})
					.catch((err: unknown) => {
						spawnAppToast({ dataMsg: err, type: 'error' });
					});
			} else {
				handleModalClose();
			}
		},
		[handleModalClose, modalMetadata.data, updateAssetRate]
	);

	const currentValues = watch();

	return (
		<div className='cryptoAsset'>
			<div className='cryptoAsset_topSect'>
				<Input
					prefixIcon='$'
					label='Minimum Value'
					size='small'
					type='number'
					disabled={!modalMetadata.isEditing}
					register={register('minimum')}
					errorMsg={errors.minimum?.message}
				/>
				<Input
					label='Confirmation Value'
					size='small'
					type='number'
					disabled={!modalMetadata.isEditing}
					register={register('confirmations')}
					errorMsg={errors.confirmations?.message}
				/>
			</div>

			<div className='cryptoAsset_rates'>
				<DataInsight
					title='Rate Ranges'
					customValue={
						<div className='insight_group'>
							<div className='insight_group_col'>
								<Text variant='body_small_bold'>NGN Rate</Text>
							</div>
							<div className='insight_group_col'>
								<Text variant='body_small_bold'>GHS Rate</Text>
							</div>
						</div>
					}
				/>

				{pairedAssetRates?.map((assetRate, index, array) => {
					const isLastIndex = index === array.length - 1;
					const isFirstIndex = index === 0;
					let titleTxt = `$${assetRate.minValueInUsd} - $${assetRate.maxValueInUsd}`;
					if (isLastIndex) titleTxt = `Above $${assetRate.maxValueInUsd - 1}`;
					if (isFirstIndex) titleTxt = `Less than $${assetRate.maxValueInUsd + 1}`;

					if (typeof currentValues !== 'object') return null;

					const newRateInNgn = currentValues[Number(assetRate.key) as unknown as keyof typeof currentValues];
					const rateInGhs = newRateInNgn / (fxRates?.NGNToGHS ?? 1);

					return (
						<DataInsight
							key={assetRate.key}
							title={titleTxt}
							className='insight_group_input_wrapper'
							customValue={
								<div className='insight_group'>
									<div className='insight_group_col'>
										<Input
											size='small'
											type='number'
											disabled={!modalMetadata.isEditing}
											register={register(assetRate.key)}
											errorMsg={errors[assetRate.key]?.message}
										/>
									</div>
									<div className='insight_group_col'>
										<Input
											size='small'
											disabled
											extValue={rateInGhs.toString()}
										/>
									</div>
								</div>
							}
						/>
					);
				})}
			</div>

			{modalMetadata.isEditing && (
				<div className='cryptoAsset_cta'>
					<Button
						variant='gray'
						onClick={handleModalClose}
					>
						Cancel
					</Button>
					<Button
						variant='blue'
						loading={isLoading}
						onClick={handleSubmit(onSubmit)}
					>
						Save Changes
					</Button>
				</div>
			)}
		</div>
	);
};

export default ViewEditCryptoAssetRate;
