import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ISettings } from 'BreetHelpers';

import { reduxPersistor } from '@/lib';

import { usersApiSlice } from '../api';

export interface AppState {
	themeMode: 'dark' | 'light';
	loggedinEmail?: string;
	isUserLoggedIn: boolean;
	settings: ISettings | null;
}

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
const persistedState = typeof window !== 'undefined' ? reduxPersistor.getData('app') : null;

const getAppThemeState = () => {
	if (persistedState?.themeMode) return persistedState.themeMode;
	return prefersDark ? 'dark' : 'light';
};

const initialState: AppState = {
	themeMode: getAppThemeState(),
	isUserLoggedIn: persistedState?.isUserLoggedIn ?? false,
	settings: null,
};

// If you are not using async thunks you can use the standalone `createSlice`.
export const appSlice = createSlice({
	name: 'app',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: (create) => ({
		setThemeMode: create.reducer((state, action: PayloadAction<'dark' | 'light'>) => {
			state.themeMode = action.payload;
			reduxPersistor.update('app', { ...persistedState, themeMode: action.payload });
		}),
		setRemoveLoggedInEmail: create.reducer((state, action: PayloadAction<string | undefined>) => {
			state.loggedinEmail = action.payload;
		}),
		logUserIn: create.reducer((state) => {
			state.isUserLoggedIn = true;
			reduxPersistor.update('app', { ...persistedState, isUserLoggedIn: true });
		}),
		logUserOut: create.reducer((state) => {
			state.isUserLoggedIn = false;
			reduxPersistor.update('app', { ...persistedState, isUserLoggedIn: false });
		}),
		setUserSettings: create.reducer((state, action: PayloadAction<ISettings>) => {
			state.settings = action.payload;
		}),
	}),
	extraReducers: (builder) => {
		builder.addMatcher(usersApiSlice.endpoints.getCurretUser.matchFulfilled, (state, { payload }) => {
			state.settings = payload.meta.settings;
		});
	},
	// You can define your selectors here. These selectors receive the slice
	// state as their first argument.
	selectors: {
		selectThemeMode: (state) => state.themeMode,
		selectLoggedinEmail: (state) => state.loggedinEmail,
		selectWithdrawalMeta: (state) => state.settings?.withdrawalMeta,
		selectBillsMeta: (state) => state.settings?.billsMeta,
		selectKycMeta: (state) => state.settings?.kycMeta,
		selectSellMeta: (state) => state.settings?.sellMeta,
		selectSwapMeta: (state) => state.settings?.swapMeta,
		selectFxRates: (state) => state.settings?.fxRates,
	},
});

export const { setThemeMode, setRemoveLoggedInEmail, logUserIn, logUserOut, setUserSettings } = appSlice.actions;

export const {
	selectThemeMode,
	selectLoggedinEmail,
	selectWithdrawalMeta,
	selectBillsMeta,
	selectKycMeta,
	selectSellMeta,
	selectSwapMeta,
	selectFxRates,
} = appSlice.selectors;
