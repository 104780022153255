import type {
	IApiPaginatedFilterQuery,
	IApiResponse,
	IApiResponsePaginated,
	IUser,
	IUserNote,
	IUserReferral,
	IUserTagType,
} from 'BreetHelpers';

import type { EditUserProfileSchemaType } from '@/features/user-management/users/[userId]/userActions/userActions.helpers';

import { apiSlice } from './apiSlice';

export const usersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCurretUser: builder.query<IApiResponse<IUser>, void>({
			query: () => ({ url: '/admin/users/current' }),
			providesTags: ['User'],
		}),
		getAllUsers: builder.query<IApiResponsePaginated<IUser[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({
				url: '/admin/users',
				params,
			}),
			providesTags: ['GetAllUsers'],
		}),
		getSingleUser: builder.query<IApiResponse<IUser>, string | undefined>({
			query: (userId) => ({
				url: `/admin/users/user/${userId}`,
			}),
			providesTags: ['SingleUser'],
		}),
		updateSingleUser: builder.mutation<IApiResponse<IUser>, { userId?: string; body: EditUserProfileSchemaType }>({
			query: ({ userId, body }) => ({
				method: 'PUT',
				url: `/admin/users/user/${userId}`,
				body,
			}),
			invalidatesTags: ['SingleUser'],
		}),
		deleteSingleUser: builder.mutation<IApiResponse<IUser>, string | undefined>({
			query: (userId) => ({
				method: 'DELETE',
				url: `/admin/users/user/${userId}`,
			}),
			invalidatesTags: ['SingleUser'],
		}),
		restoreSingleUser: builder.mutation<IApiResponse<IUser>, string | undefined>({
			query: (userId) => ({
				method: 'PATCH',
				url: `/admin/users/user/${userId}`,
			}),
			invalidatesTags: ['SingleUser'],
		}),
		getUserFinanceSummary: builder.query<IApiResponse<Record<string, Record<string, string | number>>>, string | undefined>({
			query: (userId) => ({
				url: `/admin/users/user/${userId}/summary`,
			}),
		}),
		getUserNoteHistory: builder.query<IApiResponse<IUserNote[]>, string | undefined>({
			query: (userId) => ({
				url: `/admin/users/user/${userId}/notes`,
			}),
			providesTags: ['GetUserNoteHistory'],
		}),
		tagUntagUser: builder.mutation<IApiResponse, { userId: string; tag: IUserTagType }>({
			query: ({ userId, tag }) => ({
				method: 'PUT',
				url: `/admin/users/user/${userId}/tag`,
				body: { tag },
			}),
			invalidatesTags: ['SingleUser', 'GetAllUsers', 'GetUserNoteHistory'],
		}),
		getAllUsersReferrals: builder.query<IApiResponsePaginated<IUserReferral[]>, IApiPaginatedFilterQuery>({
			query: (params) => ({ url: '/admin/users/referrals', params }),
		}),
		getUserReferrals: builder.query<IApiResponsePaginated<IUserReferral[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId }) => ({
				url: `/admin/users/user/${userId}/referrals`,
			}),
		}),
		getReferralDetails: builder.query<IApiResponse<IUserReferral>, string | undefined>({
			query: (userId) => ({
				url: `/admin/users/user/${userId}/referral`,
			}),
		}),
		verifyUserEmail: builder.mutation<IApiResponse, string>({
			query: (userId) => ({
				method: 'PUT',
				url: `/admin/users/user/${userId}/verification`,
			}),
			invalidatesTags: ['SingleUser'],
		}),
		resendUserEmailVerification: builder.mutation<IApiResponse, string>({
			query: (userId) => ({
				method: 'PATCH',
				url: `/admin/users/user/${userId}/verification`,
			}),
		}),
		freezeUser: builder.mutation<IApiResponse, string>({
			query: (userId) => ({
				method: 'PUT',
				url: `/admin/users/user/${userId}/freeze`,
			}),
			invalidatesTags: ['SingleUser'],
		}),
		unfreezeUser: builder.mutation<IApiResponse, string>({
			query: (userId) => ({
				method: 'PATCH',
				url: `/admin/users/user/${userId}/freeze`,
			}),
			invalidatesTags: ['SingleUser'],
		}),
		creditUserRewardPoints: builder.mutation<IApiResponse, Record<string, string | boolean | number>>({
			query: ({ userId, points, reason }) => ({
				method: 'PUT',
				url: `/admin/payments/user/${userId}/reward`,
				body: { points, reason },
			}),
			invalidatesTags: ['SingleUser'],
		}),
		debitUserRewardPoints: builder.mutation<IApiResponse, Record<string, string | boolean | number>>({
			query: ({ userId, points, reason }) => ({
				method: 'DELETE',
				url: `/admin/payments/user/${userId}/reward`,
				body: { points, reason },
			}),
			invalidatesTags: ['SingleUser'],
		}),
		recheckUserAML: builder.mutation<IApiResponse, string>({
			query: (userId) => ({
				method: 'PATCH',
				url: `/admin/users/user/${userId}/aml`,
			}),
			invalidatesTags: ['SingleUser'],
		}),
	}),
});

export const {
	useGetCurretUserQuery,
	useGetAllUsersQuery,
	useGetSingleUserQuery,
	useUpdateSingleUserMutation,
	useDeleteSingleUserMutation,
	useRestoreSingleUserMutation,
	useGetUserFinanceSummaryQuery,
	useLazyGetUserFinanceSummaryQuery,
	useGetUserNoteHistoryQuery,
	useTagUntagUserMutation,
	useGetAllUsersReferralsQuery,
	useGetUserReferralsQuery,
	useGetReferralDetailsQuery,
	useVerifyUserEmailMutation,
	useResendUserEmailVerificationMutation,
	useFreezeUserMutation,
	useUnfreezeUserMutation,
	useCreditUserRewardPointsMutation,
	useDebitUserRewardPointsMutation,
	useRecheckUserAMLMutation,
} = usersApiSlice;

export const selectUser = usersApiSlice.endpoints.getCurretUser.select();
export const selectGetSingleUser = (userId?: string) => usersApiSlice.endpoints.getSingleUser.select(userId);
export const selectGetUserNoteHistory = (userId?: string) => usersApiSlice.endpoints.getUserNoteHistory.select(userId);
